import {
  WILLS,
  TELEPHONE_WILLS,
  PROBATE,
  FUNERAL,
  FUNERAL_PLAN,
} from 'lib/products/constants'
import {
  WILLS_SUPPORT_START_TIME,
  WILLS_SUPPORT_END_TIME,
  WILLS_SUPPORT_ISO_DAYS_OF_WEEK,
  WILLS_SUPPORT_OVERFLOW,
  PROBATE_SUPPORT_START_TIME,
  PROBATE_SUPPORT_END_TIME,
  PROBATE_SUPPORT_ISO_DAYS_OF_WEEK,
  PROBATE_SUPPORT_OVERFLOW,
  CREMATION_SUPPORT_START_TIME,
  CREMATION_SUPPORT_END_TIME,
  CREMATION_SUPPORT_ISO_DAYS_OF_WEEK,
  CREMATION_SUPPORT_OVERFLOW,
} from 'config'

export const PRODUCT_SUPPORT_MAP = {
  [WILLS]: {
    startTime: WILLS_SUPPORT_START_TIME,
    endTime: WILLS_SUPPORT_END_TIME,
    daysOfWeek: WILLS_SUPPORT_ISO_DAYS_OF_WEEK,
    overflow: WILLS_SUPPORT_OVERFLOW,
  },
  [TELEPHONE_WILLS]: {
    startTime: WILLS_SUPPORT_START_TIME,
    endTime: WILLS_SUPPORT_END_TIME,
    daysOfWeek: WILLS_SUPPORT_ISO_DAYS_OF_WEEK,
    overflow: WILLS_SUPPORT_OVERFLOW,
  },
  [PROBATE]: {
    startTime: PROBATE_SUPPORT_START_TIME,
    endTime: PROBATE_SUPPORT_END_TIME,
    daysOfWeek: PROBATE_SUPPORT_ISO_DAYS_OF_WEEK,
    overflow: PROBATE_SUPPORT_OVERFLOW,
  },
  [FUNERAL]: {
    startTime: CREMATION_SUPPORT_START_TIME,
    endTime: CREMATION_SUPPORT_END_TIME,
    daysOfWeek: CREMATION_SUPPORT_ISO_DAYS_OF_WEEK,
    overflow: CREMATION_SUPPORT_OVERFLOW,
  },
  [FUNERAL_PLAN]: {
    startTime: CREMATION_SUPPORT_START_TIME,
    endTime: CREMATION_SUPPORT_END_TIME,
    daysOfWeek: CREMATION_SUPPORT_ISO_DAYS_OF_WEEK,
    overflow: CREMATION_SUPPORT_OVERFLOW,
  },
}
